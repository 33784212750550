import React from 'react'
import Navroute from './Router/Navroute'


export default function App() {
  return (
    <div>
      <Navroute />
    </div>
  )
}
